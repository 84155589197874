import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreCommonService {
  action = new BehaviorSubject('' as string);
  token = new BehaviorSubject('' as string);
  constructor() {}

  setAction(action: string) {
    this.action.next(action);
  }

  getAction(): BehaviorSubject<string> {
    return this.action;
  }

  setToken(token: string) {
    this.token.next(token);
  }

  getToken(): BehaviorSubject<string> {
    return this.token;
  }
}
