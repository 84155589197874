import { CommonLabels } from './models/common.model';

export const commonLabels: CommonLabels = {
  settings: 'Settings',
  academy: 'Barbara Academy',
  accept: 'Accept',
  account: 'Account',
  accuracy: 'Accuracy',
  action: 'Action',
  actions: 'Actions',
  activate: 'Activate',
  activateCapitalize: 'Enable',
  add: 'Add',
  addAsset: 'Add asset',
  addCapitalize: 'Add',
  addTag: 'Add tag',
  load: 'Load',
  adjustSegments: 'Adjust segments',
  advancedSettings: 'Advanced settings',
  all: 'All',
  alreadyExists: ' already exists',
  alreadyInPanel: 'Already in panel',
  amd64: 'AMD64',
  app: 'App',
  application: 'Application',
  applications: 'Applications',
  apps: 'Workloads',
  architecture: 'Architecture',
  arm64V8: 'ARM64/V8',
  armV7: 'ARMV7',
  assets: 'Assets',
  auditLog: 'Audit Log',
  auditLogs: 'Audit logs',
  available: 'Available',
  back: 'Back',
  backCapitalize: 'Back',
  batch: 'Batch',
  batches: 'Batches',
  browseFile: 'Browse file',
  cancel: 'Cancel',
  card: 'Card',
  change: 'Change',
  changeImage: 'Change image',
  changeLog: 'ChangeLog',
  changeLogs: 'ChangeLogs',
  cleaningData: 'CLEANING DATA',
  close: 'Close',
  collapse: 'Collapse',
  command: 'Command',
  commandSettings: 'Command settings',
  composeConfig: 'Compose config',
  config: 'Config',
  contactSales: 'Contact sales',
  copy: 'Copy',
  copyToClipboard: 'Copy to clipboard',
  create: 'Create',
  createDevice: 'Create node',
  created: 'Created',
  cropper: 'Crop',
  currentVersion: 'Current version',
  dashboard: 'Dashboard',
  deactivate: 'Deactivate',
  deactivateCapitalize: 'Deactivate',
  default: 'Default',
  delete: 'Delete',
  deleteAll: 'Delete all',
  deleteConfig: 'Delete Configuration',
  deleting: 'DELETING',
  deletingApps: 'DELETING APPLICATION',
  deprecated: 'Deprecated',
  description: 'Description',
  detail: 'Detail',
  details: 'Details',
  device: 'Node',
  devices: 'Node',
  disable: 'Disable',
  dockerCredential: 'Docker credential',
  docs: 'Docs',
  download: 'Download',
  downloadConfig: 'Download Configuration',
  downloadFile: 'Download file',
  edgeNodes: 'Nodes',
  edit: 'Edit',
  editConfig: 'Edit Configuration',
  enable: 'Enable',
  enableLogs: 'Enable logs',
  errorGettingInfo: 'Error getting info',
  errorNameAlreadyExists: 'Name already exists',
  errorObtainingInfo: 'Error obtaining info',
  errorSize: 'File size cannot exceed ',
  errorType: 'File extension not allowed, allowed extension: ',
  errorTypes: 'File extension not allowed, allowed extensions: ',
  ethernet: 'Ethernet',
  exitCode: 'Exit code',
  expand: 'Expand',
  export: 'Export',
  exportCSV: 'Export to CSV',
  failedUpdate: 'Failed update',
  failingStreak: 'FailingStreak',
  file: 'File',
  fileUpload: 'File upload',
  filter: 'Filter',
  follow: 'Follow',
  forThis: 'For this',
  forceDockerImageDownload: 'Force docker image download',
  full: 'Full',
  generalInfo: 'General info',
  generate: 'Generate',
  goodFor: 'Good for',
  gpuText: 'Serve model using GPU',
  group: 'Group',
  groups: 'Groups',
  hardware: 'Hardware',
  health: 'Health',
  help: 'Help',
  hide: 'Hide',
  hideEcosystem: 'Hide ecosystem',
  hideMenu: 'Hide menu',
  home: 'Home',
  id: 'ID',
  image: 'Image',
  import: 'Import',
  incremental: 'Incremental',
  info: 'Info',
  ip: 'IP',
  isNotValid: ' is not valid',
  isRequired: ' is required',
  lastSeen: 'Last seen',
  lastUpdate: 'Last update',
  lastVersion: 'Last version',
  launchBatch: 'Launch batch',
  less: 'Less',
  library: 'Library',
  loadConfig: 'Load config',
  loadingInfo: 'Loading info...',
  log: 'Log',
  logout: 'Logout',
  map: 'Map',
  market: 'Barbara MarketPlace',
  mb: 'MB',
  mbCapitalize: 'Mb',
  mobile: 'Mobile',
  model: 'Model',
  more: 'More',
  name: 'Name',
  network: 'Network',
  networks: 'Networks',
  new: 'New',
  newApp: 'New app',
  newDevice: 'New nodes',
  newVersion: 'New version',
  newVersionText: 'Do you want to upload a new version?',
  next: 'Next',
  noData: `We didn't find any application that satisfies your search criteria`,
  noDataFound: 'No data found',
  node: 'Node',
  nodeManager: 'Node manager',
  nodes: 'Nodes',
  notActive: 'Not active',
  notAvailable: 'Not available',
  of: 'of',
  off: 'Off',
  offline: 'Offline',
  ok: 'Ok',
  on: 'On',
  online: 'Online',
  os: 'OS',
  osFamily: 'OS Family',
  osImage: 'OS Image',
  osImages: 'OS Images',
  osVersion: 'OS Version',
  performingFullUpdate: 'Performing full update',
  performingIncrementalUpdate: 'Performing incremental update',
  platformCompatibility: 'Platform compatibility',
  ports: 'Ports',
  preferences: 'Preferences',
  proxy: 'Proxy',
  reboot: 'Reboot',
  recentDevices: 'Recent Nodes',
  recentDevicesNoData: 'No recent nodes found',
  refresh: 'Refresh',
  reinstall: 'Reinstall',
  reinstalling: 'Reinstalling',
  remove: 'Remove',
  requiresOsInstallation: 'Requires OS installation',
  restart: 'Restart',
  result: 'Result',
  runAutomaticallyAfterInstall: 'Run automatically after install',
  running: 'Running',
  runningApp: 'RUNNING',
  save: 'Save',
  saveChanges: 'Save changes',
  search: 'Search',
  secrets: 'Secrets',
  see: 'See',
  seeLess: 'See less',
  seeMore: 'See more',
  select: 'Select',
  selectConfiguration: 'Select configuration',
  selectFile: 'Select file',
  selected: 'Selected',
  send: 'Send',
  service: 'Service',
  show: 'Show',
  showAlternativeUpdateOptions: 'Show alternative update options',
  showEcosystem: 'Show ecosystem',
  showMenu: 'Show menu',
  showing: 'Showing',
  shutDown: 'Shut down',
  size: 'Size',
  skipAndSend: 'Skip and send',
  spaces: 'Workloads',
  speed: 'Speed',
  startApp: 'Start',
  startAppCapitalize: 'Start',
  startServingModelAutomaticallyAfterInstall: 'Start serving model automatically after install',
  state: 'State',
  status: 'Status',
  stopApp: 'Stop',
  stopAppCapitalize: 'Stop',
  stoppedApp: 'STOPPED',
  studio: 'Barbara Studio',
  submit: 'Submit',
  tag: 'Tag',
  tags: 'Tags',
  textNewDevice: 'Register new node',
  timeStamp: 'Time',
  type: 'Type',
  typeTheActionYouWishToComplete: 'Type the action you wish to complete',
  uninstall: 'Uninstall',
  upToDate: 'Up to date',
  update: 'Update',
  updateAvailable: 'Update available',
  updating: 'UPDATING',
  updatingCapitalize: 'Updating',
  upload: 'Upload',
  uploadConfig: 'Upload Configuration',
  uploadFile: 'Upload file',
  uploadFromFile: 'Upload from file',
  uploading: 'UPLOADING',
  user: 'User',
  users: 'Users',
  version: 'Version',
  versionName: 'Version name',
  versions: 'Versions',
  viewDetails: 'View details',
  viewGrid: 'View Grid',
  viewList: 'View List',
  viewMap: 'View Map',
  volume: 'Volume',
  vpn: 'VPN',
  wifi: 'Wifi',
  wipeAndBrick: 'Wipe and brick',
  wlan: 'WLAN',
  wrap: 'Wrap'
};
