import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexNoData,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis
} from 'ng-apexcharts';

export enum EAgentCapabilities {
  DATE = 0,
  VPN = 1,
  DOCKER_PRUNE = 2,
  DOCKER_CREDENTIALS = 3,
  SECRETS = 4,
  DOCKER_SIMPLIFICATION = 5,
  DYNAMIC_DOCKERS = 6,
  AGENT_1_3_0 = 7,
  VOLUMES = 8,
  NEWDOCKER = 9,
  STANDALONE = 10,
  PROXY = 11,
  STANDALONEV2 = 12,
  REDESV2 = 13, //redes 2.0 ips multiples
  MLOPSV2 = 14 //puertos y volumenes, rollback, mlops v2, appconfig, ntp y hostname
}

export enum DeviceOperation {
  'Full',
  'Reader',
  'None'
}

export enum EnumMetricData {
  Connection = 0,
  Temperature = 1,
  CPU = 2,
  RAM = 3,
  DISK = 4,
  Files = 5
}

export enum SourceData {
  General = 0,
  App1 = 1,
  App2 = 2,
  App3 = 3
}

export interface DataPersit {
  native: ModelDataPersist;
  docker: ModelDataPersist;
}

export interface ModelDataPersist {
  space: number;
  size: string;
}

export interface SpaceLogs {
  space: string;
  logs: Log[];
  showLogs: boolean;
  showConfig: boolean;
  deletingApplication: boolean;
  deletingPersistent: boolean;
  updatingPersistent: boolean;
  updatingDockerInfo: boolean;
  showInspectDocker: boolean;
  updatingDockerRun: boolean;
  updatingApplication: boolean;
  buildingApplication: boolean;
  runDocker: any;
  loading: boolean;
}

export interface ActivationEventModel {
  cardName: string;
  expand: boolean;
}

export interface SourceModel {
  value: number;
  label: string;
  data: string;
}

export interface MetricModel {
  value: number;
  label: string;
  data: string;
}

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  colors: string[];
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  noData: ApexNoData;
}

export interface Network {
  defaultGateway: string[];
  interfaces: [];
}

export interface Telemetry {
  deviceId: string;
  cpuTemperature: number;
  cpuUsage: number;
  diskUsage: number;
  diskTotal: number;
  ramUsage: number;
  ramTotal: number;
  storeDate: string;
  upSince: string;
  processes?: any;
  _id?: string;
  utc?: Date;
  networkStatus?: Network;
}

export interface TelemetryLatency {
  current: {
    latency: number;
  };
  lastUpdate: Date;
  status: StatusLatency[];
  sent: SentLatency;
}

export interface StatusLatency {
  code: number;
  msg: string;
  timestamp: Date;
  utcNs: number;
}

export interface SentLatency {
  config: {
    latency: number;
  };
  pending: boolean;
  timestamp: Date;
}

export interface AssetsModel {
  name: string;
  path: string;
}

export interface DockerInfo {
  containerInfo: object;
  timestamp: string;
}

export interface Log {
  report: string;
  container: string;
  timestamp: string;
  elasticId: string;
}

export interface IncrementalOtaModel {
  _id?: string;
  companyId: string;
  createdTimestamp: Date;
  buildTimestamp: Date;
  fromFullOTAId: string;
  toFullOTAId: string;
  fromURL: string;
  toURL: string;
  incrementalOTA_hash: string;
  incrementalOTA_utc: string;
  incrementalOTA_size: number;
  pgp_url: string;
  pgp_hash: string;
  sign_url: string;
  sign_hash: string;
  status: number; // Status: 0 - no build, 1 - building, 2 - buildOK, 3 - buildNOK
}

export interface IncrementalAgentModel {
  _id?: string;
  companyId: string;
  createdTimestamp: Date;
  buildTimestamp: Date;
  fromFullAgentId: string;
  toFullAgentId: string;
  fromURL: string;
  toURL: string;
  incrementalAgent_hash: string;
  incrementalAgent_utc: string;
  incrementalAgent_size: number;
  pgp_agent_url: string;
  pgp_agent_hash: string;
  sign_agent_url: string;
  sign_agent_hash: string;
  status: number; // Status: 0 - no build, 1 - building, 2 - buildOK, 3 - buildNOK
}

export interface FullOtaModel {
  _id?: string;
  osVersionId: string;
  version: string;
  createdTimestamp: Date;
  ota_hash: string;
  ota_utc: string;
  ota_size: number;
  pgp_url: string;
  pgp_hash: string;
  pgp_size: number;
  sign_url: string;
  sign_hash: string;
}

export interface FullAgentModel {
  _id?: string;
  agentVersionId: string;
  version: string;
  createdTimestamp: Date;
  agent_hash: string;
  agent_utc: string;
  agent_size: number;
  pgp_agent_url: string;
  pgp_agent_hash: string;
  pgp_agent_size: number;
  sign_agent_url: string;
  sign_agent_hash: string;
}

export interface CustomDataModel {
  userId: string;
  deviceDetails?: {
    priorities: CustomData[];
  };
  recentDeviceList?: {
    deviceId: string;
    deviceName: string;
  }[];
  timestamp: number;
  _v?: number;
  _id?: string;
}

export interface Layaout {
  visible: boolean;
  editable: boolean;
  expanded: boolean;
}

export interface State {
  priority: string;
  message: string;
}

export interface NativeCardState {
  runState: State;
  updateState: State;
  persistState: State;
}

export interface DockerCardState {
  runState: State;
  updateState: State;
  persistState: State;
  inspectState: State;
}

export interface dataCustomDetail {
  position: number;
  column: number;
  expanded: boolean;
}

export interface CustomData {
  id?: string;
  hiddenDevice?: boolean;
  disabled?: boolean;
  data?: {
    spaceName?: number;
  };
  spaceId?: string;
  hidden: boolean;
  expanded: boolean;
  layout: CustomDataLayaout;
  title: string;
  subtitle?: string;
  type:
    | 'info'
    | 'docker'
    | 'native'
    | 'config'
    | 'model'
    | 'marketplace'
    | 'network'
    | 'network2'
    | 'volumes'
    | 'telemetry'
    | 'analytics'
    | 'credentials'
    | 'secrets';
}

export interface CustomDataSegment {
  visible: boolean;
  editable?: boolean;
  expanded: boolean;
  enable?: boolean;
  follow?: boolean;
  variables?: boolean;
  wrap?: boolean;
  time?: boolean;
  container?: boolean;
  darkMode?: boolean;
  incompleteCommands?: boolean;
}

export interface CustomDataLayaout {
  main?: CustomDataSegment;
  analytics?: CustomDataSegment;
  telemetry?: CustomDataSegment;
  network?: CustomDataSegment;
  appconfig?: CustomDataSegment;
  volumes?: CustomDataSegment;
  inference?: CustomDataSegment;
  hostname?: CustomDataSegment;
  advancedConfig?: CustomDataSegment;
  interfaces?: CustomDataSegment;
  incompleteCommands?: CustomDataSegment;
  variables?: CustomDataSegment;
  log?: CustomDataSegment;
  info?: CustomDataSegment;
  status?: CustomDataSegment;
  credentials?: CustomDataSegment;
  secrets?: CustomDataSegment;
  groups?: CustomDataSegment;
  tags?: CustomDataSegment;
  persist?: CustomDataSegment;
  process?: CustomDataSegment;
  dockerComposeInfo?: CustomDataSegment;
}

export interface DockerCredentialsListModel {
  credentialList: DockerCredentialModel[];
  lastUpdated: string;
}

export interface DockerCredentialModel {
  password: string;
  user: string;
  server: string;
  status?: EDockerCredentialStatus[]; // nuevos códigos ver status-card.pipe.ts
  _id?: string;
  update: {
    pending: boolean;
    timestamp: Date;
  };
  delete: {
    pending: boolean;
    timestamp: Date;
  };
}

export interface EDockerCredentialStatus {
  code: EDockerStatusCode;
  msg: string;
  timestamp: Date;
}

export enum EDockerStatusCode {
  DOCKER_CREDENTIALS_ADD_UNKNOWN_ERROR = 21400,
  DOCKER_CREDENTIALS_ADD_SAME_ID_DIFF_VALUE_FOUND = 21401,
  DOCKER_CREDENTIALS_ADD_NO_SERVER_FOUND = 21402,
  DOCKER_CREDENTIALS_ADD_SAME_SERVER_FOUND = 21403,
  DOCKER_CREDENTIALS_ADD_DECODE_SERVER_FAILED = 21404,
  DOCKER_CREDENTIALS_ADD_NO_USER_FOUND = 21405,
  DOCKER_CREDENTIALS_ADD_DECODE_USER_FAILED = 21406,
  DOCKER_CREDENTIALS_ADD_NO_PASSWORD_FOUND = 21407,
  DOCKER_CREDENTIALS_ADD_DECODE_PASSWORD_FAILED = 21408,
  DOCKER_CREDENTIALS_ADD_LOGIN_FAILED = 21409,
  DOCKER_CREDENTIALS_ADD_SUCCESS = 21410,
  // CREDENTIALS - DELETE
  DOCKER_CREDENTIALS_DELETE_UNKNOWN_ERROR = 21500,
  DOCKER_CREDENTIALS_DELETE_NO_SERVER_FOUND = 21501,
  DOCKER_CREDENTIALS_DELETE_DECODE_SERVER_FAILED = 21502,
  DOCKER_CREDENTIALS_DELETE_NO_USER_FOUND = 21503,
  DOCKER_CREDENTIALS_DELETE_DECODE_USER_FAILED = 21504,
  DOCKER_CREDENTIALS_DELETE_NO_PASSWORD_FOUND = 21505,
  DOCKER_CREDENTIALS_DELETE_DECODE_PASSWORD_FAILED = 21506,
  DOCKER_CREDENTIALS_DELETE_CREDENTIAL_NOT_FOUND = 21507,
  DOCKER_CREDENTIALS_DELETE_LOGOUT_FAILED = 21508,
  DOCKER_CREDENTIALS_DELETE_SUCCESS = 21509,
  // CREDENTIALS - UPDATE
  DOCKER_CREDENTIALS_UPDATE_UNKNOWN_ERROR = 21600,
  DOCKER_CREDENTIALS_UPDATE_NO_SERVER_FOUND = 21601,
  DOCKER_CREDENTIALS_UPDATE_DECODE_SERVER_FAILED = 21602,
  DOCKER_CREDENTIALS_UPDATE_NO_USER_FOUND = 21603,
  DOCKER_CREDENTIALS_UPDATE_DECODE_USER_FAILED = 21604,
  DOCKER_CREDENTIALS_UPDATE_NO_PASSWORD_FOUND = 21605,
  DOCKER_CREDENTIALS_UPDATE_DECODE_PASSWORD_FAILED = 21606,
  DOCKER_CREDENTIALS_UPDATE_LOGOUT_FAILED = 21607,
  DOCKER_CREDENTIALS_UPDATE_LOGIN_FAILED = 21608,
  DOCKER_CREDENTIALS_UPDATE_SUCCESS = 21609
}

export interface SecretsListModel {
  secretList: SecretModel[];
  lastUpdated: string;
}

export interface SecretModel {
  name: string;
  status?: number; // NEW=0, DEVICE_SUCCEED=1, DEVICE_FAILED=2, DELETION_REQUIRED=3
  value: string;
  _id?: string;
}

export interface MapLabels {
  cancel: string;
  title: string;
}

export interface DetailDeviceLabels {
  activateCapitalize: string;
  addCard: string;
  addText1: string;
  addText2: string;
  all: string;
  analytics: string;
  back: string;
  collapse: string;
  config: string;
  copy: string;
  credentials: string;
  deactivateCapitalize: string;
  deviceVersion: string;
  dockerActions: string;
  drag: string;
  edit: string;
  editDevice: string;
  expand: string;
  firmwareUpdates: string;
  generalInfo: string;
  groups: string;
  hideNodeOptions: string;
  id: string;
  info: string;
  manageDevice: string;
  managerVersion: string;
  modalErrorVersionText1: string;
  modalErrorVersionText2: string;
  modalErrorVersionText3: string;
  modalErrorVersionText4: string;
  modalErrorVersionText5: string;
  modalErrorVersionText6: string;
  modalErrorVersionText7: string;
  modalErrorVersionText8: string;
  modalErrorVersionTitle: string;
  name: string;
  network: string;
  new: string;
  osVersion: string;
  secrets: string;
  showNodeOptions: string;
  tags: string;
  telemetry: string;
  updateAvailable: string;
  updateNodeManager: string;
  updateOS: string;
  dockerVolumes: string;
  appNative: string;
  appDocker: string;
  appMarket: string;
  model: string;
}

export interface AppDeviceLabels {
  collapse: string;
  hideCard: string;
  expand: string;
  showOptions: string;
  hideOptions: string;
  pending: string;
  pendingCommands: string;
  appOutdated: string;
  appPreparing: string;
  appBuilding: string;
  appRunning: string;
  appStopped: string;
  appError: string;
  appConfiguring: string;
  processes: string;
  dockerComposeInfo: string;
  persistantFolder: string;
}

export interface DeviceLocationModalLabels {
  cancel: string;
  current: string;
  edit: string;
  save: string;
}

export interface InfoDetailLabels {
  activate: string;
  add: string;
  architecture: string;
  collapse: string;
  enableProxy: string;
  disableProxy: string;
  connected: string;
  connectionStatus: string;
  coordinates: string;
  copy: string;
  cpu: string;
  delete: string;
  device: string;
  disconnected: string;
  edit: string;
  editConfig: string;
  expand: string;
  family: string;
  group: string;
  hardware: string;
  id: string;
  lastSeen: string;
  lastUpdated: string;
  location: string;
  loadingInfo: string;
  map: string;
  network: string;
  notAvailable: string;
  noData: string;
  node: string;
  nodeMetrics: string;
  metrics: string;
  time: string;
  noProvisionedText: string;
  noTags: string;
  organization: string;
  upSince: string;
  vpn: string;
  vpnErrorConfig: string;
}

export interface DockerDeviceLabels {
  cleaningData: string;
  collapse: string;
  config: string;
  delete: string;
  enviromentVariables: string;
  deleting: string;
  deletingApps: string;
  dockerInfo: string;
  dockerComposeInfo: string;
  noDockerComposeInfo: string;
  appConfig: string;
  dockerLogs: string;
  dockerName: string;
  dockerPersist: string;
  expand: string;
  noInstalledDocker: string;
  refresh: string;
  runningApp: string;
  sendDocker: string;
  startApp: string;
  startAppCapitalize: string;
  stateInfo: string;
  stopApp: string;
  stopAppCapitalize: string;
  stopBuild: string;
  stoppedApp: string;
  title: string;
  update: string;
  updating: string;
  updatingApps: string;
  upload: string;
  waiting: string;
}

export interface AnalyticsLabels {
  absoluteTabLabel: string;
  export: string;
  exportChartAsLabel: string;
  lastLabel: string;
  modalTitle: string;
  noData: string;
  rangeLabel: string;
  relativeTabLabel: string;
  selectMetricLabel: string;
  selectSourcesLabel: string;
  selectTimeLabel: string;
  configureChart: string;
}

export interface VariablesLabels {
  environmentVariable: string;
  environmentVariables: string;
  environmentVariablesEmpty: string;
  name: string;
  showPassword: string;
  hidePassword: string;
  close: string;
  value: string;
}

export interface AppConfigLabels {
  addNewGroup: string;
  duplicateName: string;
  key: string;
  lastUpdate: string;
  loading: string;
  name: string;
  noDataFound: string;
  refresh: string;
  requiredName: string;
  sendConfiguration: string;
  update: string;
  value: string;
}

export interface NativeAppLabel {
  appLogs: string;
  appName: string;
  appStatus: string;
  cleaningData: string;
  deleting: string;
  deletingApps: string;
  installingApp: string;
  noAppInstalled: string;
  persistApp: string;
  runningApp: string;
  sendApp: string;
  startApp: string;
  startingApp: string;
  stopApp: string;
  stoppedApp: string;
  stoppingApp: string;
  updating: string;
  zeroPersist: string;
}

export interface TelemetryLabels {
  collapse: string;
  config: string;
  expand: string;
  frequency: string;
  noData: string;
  process: string;
  runningProcess: string;
  runningProcesses: string;
  title: string;
  modalTitle: string;
  name: string;
}

export interface LogLabels {
  activate: string;
  delete: string;
  exportCSV: string;
  filter: string;
  search: string;
  follow: string;
  timeStamp: string;
  wrap: string;
  darkMode: string;
  lightMode: string;
  openInNewWindow: string;
}

export interface ManagerUpdateModalLabels {
  avalaibleVersionLabel: string;
  close: string;
  lastVersionLabel: string;
  mbCapitalize: string;
  agent: string;
  modalTitle: string;
  osVersion1: string;
  osVersion2: string;
  sendFullUpdateLabel: string;
  sendFullUpdateValue1: string;
  sendFullUpdateValue2: string;
  sendFullUpdateValue3: string;
  sendUpdateLabel: string;
  sizeLabel: string;
  updatingLabel: string;
  versionLabel: string;
  versionOutOfDateLabel: string;
  versionTitle: string;
}

export interface UpdateModalLabels {
  avalaibleVersionLabel: string;
  close: string;
  incrementalVersionLabel: string;
  lastVersionLabel: string;
  mbCapitalize: string;
  modalTitle: string;
  sendFullUpdateLabel: string;
  sendFullUpdateValue1: string;
  os: string;
  sendFullUpdateValue2: string;
  sendFullUpdateValue3: string;
  sendIncrementalUpdateLabel: string;
  sendIncrementalUpdateValue1: string;
  sendIncrementalUpdateValue2: string;
  sendIncrementalUpdateValue3: string;
  sendUpdateLabel: string;
  sizeLabel: string;
  updatingLabel: string;
  versionLabel: string;
  versionOutOfDateLabel: string;
  versionTitle: string;
}

export interface DockerSettingsModalLabels {
  actions: string;
  add: string;
  addAsset: string;
  assets: string;
  rollback: string;
  show: string;
  ssidHidden: string;
  hide: string;
  showPassword: string;
  back: string;
  cancel: string;
  regenerate: string;
  copy: string;
  close: string;
  standaloneCredentials: string;
  confirmDeleteAllAssetsButton: string;
  confirmDeleteAllAssetsHeader: string;
  confirmDeleteAllAssetsText: string;
  confirmDeleteAllAssetsTitle: string;
  confirmDeleteAssetButton: string;
  confirmDeleteAssetHeader: string;
  confirmDeleteAssetText: string;
  confirmDeleteAssetTitle: string;
  confirmPruneAllButton: string;
  confirmPruneAllHeader: string;
  confirmPruneAllText: string;
  confirmPruneAllTitle: string;
  confirmPruneContainersButton: string;
  confirmPruneContainersHeader: string;
  confirmPruneContainersText: string;
  confirmPruneContainersTitle: string;
  confirmPruneImagesButton: string;
  confirmPruneImagesHeader: string;
  confirmPruneImagesText: string;
  confirmPruneImagesTitle: string;
  confirmPruneNetworksButton: string;
  confirmPruneNetworksHeader: string;
  confirmPruneNetworksText: string;
  confirmPruneNetworksTitle: string;
  confirmPruneVolumesButton: string;
  confirmPruneVolumesHeader: string;
  confirmPruneVolumesText: string;
  confirmPruneVolumesTitle: string;
  confirmRestartButton: string;
  confirmRestartHeader: string;
  confirmRestartText: string;
  confirmRestartTitle: string;
  delete: string;
  deleteAll: string;
  download: string;
  export: string;
  file: string;
  found: string;
  infoPruneAllText1: string;
  infoRestartText1: string;
  infoRestartText2: string;
  less: string;
  login: string;
  messageErrorSize: string;
  modalTitle: string;
  more: string;
  name: string;
  noAssets: string;
  nodeAssets: string;
  pruneAll: string;
  pruneContainers: string;
  pruneDocker: string;
  pruneImages: string;
  pruneNetworks: string;
  pruneVolumes: string;
  restart: string;
  restartDocker: string;
  send: string;
  status: string;
  total: string;
  upload: string;
  uploading: string;
  value: string;
}

export interface ManageModalLabels {
  activate: string;
  cancel: string;
  back: string;
  close: string;
  confirmActivateButton: string;
  cautionTextDeactivate: string;
  confirmActivateHeader: string;
  confirmActivateText: string;
  confirmActivateTitle: string;
  confirmDeactivateButton: string;
  confirmDeactivateHeader: string;
  confirmDeactivateText: string;
  confirmDeactivateText2: string;
  confirmDeactivateTitle: string;
  confirmDeleteButton: string;
  confirmDeleteHeader: string;
  cautionTextDelete: string;
  cautionTextShutDown: string;
  cautionTextWipeAndBrick: string;
  confirmDeleteText: string;
  confirmDeleteText2: string;
  confirmDeleteTitle: string;
  confirmRebootButton: string;
  confirmRebootHeader: string;
  confirmRebootText: string;
  confirmRebootTitle: string;
  confirmShutDownButton: string;
  confirmShutDownHeader: string;
  confirmShutDownText: string;
  confirmShutDownTitle: string;
  confirmWipeAndBrickButton: string;
  confirmWipeAndBrickHeader: string;
  confirmWipeAndBrickText: string;
  confirmWipeAndBrickTitle: string;
  deactivate: string;
  delete: string;
  infoActivateText: string;
  infoDeactivateText1: string;
  infoDeactivateText2: string;
  infoDeactivateText3: string;
  infoDeleteText1: string;
  infoDeleteText2: string;
  infoRebootText1: string;
  infoRebootText2: string;
  infoShutDownText: string;
  infoWipeAndBrickText1: string;
  infoWipeAndBrickText2: string;
  messageWarning: string;
  modalTitle: string;
  reboot: string;
  shutDown: string;
  wipeAndBrick: string;
  labelForm: string;
  placeholderCommon: string;
}

export interface EditDeviceModalLabels {
  add: string;
  addAsset: string;
  addTag: string;
  assets: string;
  back: string;
  changeImage: string;
  close: string;
  delete: string;
  download: string;
  messageErrorSize: string;
  modalTitle: string;
  name: string;
  noAssets: string;
  tag: string;
  tagList: string;
  upload: string;
  uploading: string;
}

export interface UploadModalLabels {
  application: string;
  back: string;
  enabledLogs: string;
  buttonUpload: string;
  model: string;
  selectModel: string;
  cancel: string;
  titleModel: string;
  configuration: string;
  existingVersion: string;
  textInfoModel: string;
  forceDocker: string;
  newVersion: string;
  next: string;
  nextSecrets: string;
  runAutomaticallyAfterInstall: string;
  selectApp: string;
  selectVersion: string;
  send: string;
  textInfoAplication: string;
  textInfoApplication: string;
  textInfoVersion: string;
  titleDocker: string;
  titleMarketplace: string;
  titleNative: string;
  uploadFile: string;
  version: string;
  versionName: string;
}

export interface ExportCsvModalLabels {
  byLines: string;
  byTime: string;
  cancel: string;
  datePickerLabel: string;
  exportLabel: string;
  exportText: string;
  isRequired: string;
  last: string;
  lines: string;
  ok: string;
  title: string;
}

export interface PictureModalLabels {
  cancel: string;
  titleIcon: string;
  titleImagen: string;
  upload: string;
  zoomIn: string;
  zoomOut: string;
}

export interface ChangeImageModalLabels {
  background: string;
  cancel: string;
  color: string;
  delete: string;
  fontSize: string;
  generate: string;
  generateIcon: string;
  generatePicture: string;
  textPlaceholder: string;
  titleDevicePicture: string;
  titleIcon: string;
  titlePicture: string;
  upload: string;
}

export interface CredentialsLabels {
  add: string;
  addCredentialFromFile: string;
  addCredentials: string;
  required: string;
  addNewCredential: string;
  invalidUrl: string;
  cancel: string;
  close: string;
  comfirmDeleteAllCredentialText1: string;
  comfirmDeleteAllCredentialText2: string;
  confirmDeleteAllCredentialTitle: string;
  confirmDeleteCredentialText: string;
  confirmDeleteCredentialText2: string;
  confirmDeleteCredentialTitle: string;
  credential: string;
  credentials: string;
  delete: string;
  deleteAll: string;
  deleteCredential: string;
  dockerCredential: string;
  downloadTemplate: string;
  downloadFile: string;
  edit: string;
  editCredential: string;
  errorAddingCredentials: string;
  errorCredentialAlreadyExist: string;
  errorCredentialEmptyValue: string;
  errorCsvEmpty: string;
  errorCsvFormat: string;
  errorCsvTemplate: string;
  export: string;
  fileImportSummary: string;
  hidePassword: string;
  import: string;
  lastUpdate: string;
  loadingInfo: string;
  more: string;
  noCredentials: string;
  noDataFound: string;
  ok: string;
  password: string;
  repository: string;
  save: string;
  selectFile: string;
  showPassword: string;
  status: string;
  totalCredentialsFound: string;
  upload: string;
  uploadFile: string;
  uploadFromFile: string;
  userName: string;
}

export interface VolumesLabels {
  add: string;
  app: string;
  cancel: string;
  close: string;
  container: string;
  containers: string;
  created: string;
  delete: string;
  inUse: string;
  lastUpdate: string;
  loadingInfo: string;
  name: string;
  noDataFound: string;
  noVolumes: string;
  ok: string;
  prune: string;
  service: string;
  target: string;
  usedBy: string;
  volumeName: string;
  volume: string;
  volumes: string;
}

export interface SecretsLabels {
  add: string;
  addSecretFromFile: string;
  addSecrets: string;
  addFromTxt: string;
  addNewSecret: string;
  cancel: string;
  close: string;
  comfirmDeleteAllSecretText1: string;
  comfirmDeleteAllSecretText2: string;
  confirmDeleteAllSecretTitle: string;
  confirmDeleteSecretText: string;
  confirmDeleteSecretText2: string;
  confirmDeleteSecretTitle: string;
  secret: string;
  secrets: string;
  delete: string;
  deleteAll: string;
  deleteSecret: string;
  downloadTemplate: string;
  downloadFile: string;
  edit: string;
  editSecret: string;
  errorAddingSecrets: string;
  errorSecretAlreadyExist: string;
  errorSecretEmptyValue: string;
  errorTxtFormat: string;
  errorTxtTemplate: string;
  export: string;
  fileImportSummary: string;
  hidePassword: string;
  import: string;
  lastUpdate: string;
  loadingInfo: string;
  more: string;
  noSecrets: string;
  noDataFound: string;
  ok: string;
  value: string;
  name: string;
  save: string;
  secretName: string;
  secretValue: string;
  selectFile: string;
  showPassword: string;
  status: string;
  totalSecretsFound: string;
  unauthorized: string;
  upload: string;
  uploadFile: string;
  uploadFromFile: string;
  fromFile: string;
}
