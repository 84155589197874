import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DeviceModel } from '../common/models/common.model';
import { StorageService } from '../common/services/storage.service';
import { Telemetry } from '../devices/models/device-detail.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceStoreService {
  device = new BehaviorSubject({} as DeviceModel);
  goToDevice = new BehaviorSubject(false);
  listDeviceRecent = new BehaviorSubject([]);
  deviceId = new BehaviorSubject('');
  activateLogChecked = new BehaviorSubject(false);
  onConfirmedSend = new BehaviorSubject(false);
  dataApp = new BehaviorSubject(null);
  step = new BehaviorSubject(1);
  jsonValid = new BehaviorSubject(false);
  dataProxy = new BehaviorSubject(null);
  dataProxyFull = new BehaviorSubject(null);
  lastTelemetry = new BehaviorSubject(null);
  fakeChartTelemetry = new BehaviorSubject(null);
  processListTelemetry = new BehaviorSubject(null);
  networkStatusTelemetry = new BehaviorSubject(null);
  jsonConfig = new BehaviorSubject(null);
  startIntervalTelemetry = new BehaviorSubject(false);
  defaultGateway = new BehaviorSubject(null);
  defaultGatewayIp = new BehaviorSubject(null);
  secrets = new BehaviorSubject(null);
  userList = new BehaviorSubject(null);

  constructor(private storageService: StorageService) {}

  setDefaultGateway(data: string) {
    this.defaultGateway.next(data.toUpperCase());
  }
  setDefaultGatewayIp(data: string) {
    this.defaultGatewayIp.next(data);
  }

  setUsers(data: any) {
    this.userList.next(data.fullDocument.users);
  }
  getUsers(): BehaviorSubject<any> {
    return this.userList;
  }

  getDefaultGateway(): BehaviorSubject<string> {
    return this.defaultGateway;
  }
  getDefaultGatewayIp(): BehaviorSubject<string> {
    return this.defaultGatewayIp;
  }

  setNetworkStatusTelemetry(data: any) {
    this.networkStatusTelemetry.next(data);
  }

  getNetworkStatusTelemetry(): BehaviorSubject<any> {
    return this.networkStatusTelemetry;
  }

  getJsonConfig(): BehaviorSubject<any> {
    return this.jsonConfig;
  }

  setJsonConfig(data: any) {
    this.jsonConfig.next(data);
  }

  setStatusIntervalTelemetry(data: boolean) {
    this.startIntervalTelemetry.next(data);
  }

  getStatusIntervalTelemetry(): BehaviorSubject<boolean> {
    return this.startIntervalTelemetry;
  }

  setProcessListTelemetry(data: any) {
    this.processListTelemetry.next(data);
  }

  getProcessListTelemetry(): BehaviorSubject<any> {
    return this.processListTelemetry;
  }

  setFakeChartTelemetry(data: any) {
    this.fakeChartTelemetry.next(data);
  }

  getFakeChartTelemetry(): BehaviorSubject<any> {
    return this.fakeChartTelemetry;
  }

  setLastTelemetry(lastTelemetry: Telemetry) {
    this.lastTelemetry.next(lastTelemetry);
  }

  getLastTelemetry(): BehaviorSubject<any> {
    return this.lastTelemetry;
  }

  setDevice(device: DeviceModel) {
    this.device.next(device);
  }

  getDevice(): BehaviorSubject<DeviceModel> {
    return this.device;
  }

  setJsonValid(status: boolean) {
    this.jsonValid.next(status);
  }

  setDataProxyFull(data: any) {
    this.dataProxyFull.next(data);
  }

  setDataProxy(data: any) {
    this.dataProxy.next(data);
  }

  removeDataProxy() {
    this.dataProxy.next(null);
  }
  setSecrets(data: any) {
    this.secrets.next(data);
  }
  getSecrets(): BehaviorSubject<any> {
    return this.secrets;
  }
  getDataProxy(): BehaviorSubject<any> {
    return this.dataProxy;
  }

  getJsonValid(): BehaviorSubject<boolean> {
    return this.jsonValid;
  }

  setStep(step: number) {
    this.step.next(step);
  }

  getStep(): BehaviorSubject<number> {
    return this.step;
  }

  setConfirmedSend(status: boolean) {
    this.onConfirmedSend.next(status);
  }

  setDataApp(data: any) {
    this.dataApp.next(data);
  }

  getDataApp(): BehaviorSubject<any> {
    return this.dataApp;
  }

  getConfirmSend(): BehaviorSubject<boolean> {
    return this.onConfirmedSend;
  }

  saveActivateLogChecked(mode: boolean) {
    this.activateLogChecked.next(mode);
  }

  getDeviceListRecent(): BehaviorSubject<any> {
    return this.listDeviceRecent;
  }

  setDeviceListRecent(deviceList: any) {
    this.listDeviceRecent.next(deviceList);
  }

  selectedDevice(deviceId: string) {
    this.deviceId.next(deviceId);
  }

  getSelectedDevice(): BehaviorSubject<string> {
    return this.deviceId;
  }

  getDataProxyFull(): BehaviorSubject<any> {
    return this.dataProxyFull;
  }

  changeRecentDevice(status: boolean) {
    this.goToDevice.next(status);
  }

  getChangeRecentDevice() {
    return this.goToDevice;
  }
}
