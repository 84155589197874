import { commonLabels } from 'app/main/common/common.labels';
import {
  AnalyticsLabels,
  AppConfigLabels,
  AppDeviceLabels,
  ChangeImageModalLabels,
  CredentialsLabels,
  DetailDeviceLabels,
  DockerDeviceLabels,
  DockerSettingsModalLabels,
  EditDeviceModalLabels,
  ExportCsvModalLabels,
  InfoDetailLabels,
  LogLabels,
  ManageModalLabels,
  ManagerUpdateModalLabels,
  MapLabels,
  NativeAppLabel,
  PictureModalLabels,
  SecretsLabels,
  TelemetryLabels,
  UpdateModalLabels,
  UploadModalLabels,
  VariablesLabels,
  VolumesLabels
} from '../models/device-detail.model';
import { ConfirmDialogLabels } from '../models/device-list-model';

export const confirmDialogLabels: ConfirmDialogLabels = {
  button: 'Activate',
  cancel: commonLabels.cancel,
  secondSubtitle:
    ' Once activated, you can easily enable the VPN in your nodes from this icon. To configure the VPN in your local computer, please follow the instructions you can find',
  subtitle:
    'After that period the service will be disabled unless you purchase a license. Please contact our Sales Department for more information.',
  title: 'The VPN service has a trial period of 1 month',
  title2: 'DO YOU WANT TO ACTIVATE IT?'
};
export const mapLabels: MapLabels = {
  cancel: commonLabels.cancel,
  title: 'Location'
};
export const detailDeviceLabels: DetailDeviceLabels = {
  activateCapitalize: commonLabels.activateCapitalize,
  addCard: 'Add card',
  addText1: 'NO APP RUNNING IN THIS NODE',
  addText2: 'CLICK ON ‘ADD’ TO INSTALL A NEW APP',
  all: commonLabels.all,
  analytics: 'Analytics',
  back: commonLabels.backCapitalize,
  collapse: commonLabels.collapse,
  config: 'Global config',
  copy: 'Copy to clipboard',
  credentials: 'Docker credentials',
  deactivateCapitalize: commonLabels.deactivateCapitalize,
  deviceVersion: 'STATUS',
  dockerActions: 'Advanced options',
  drag: 'Drag & Drop mode',
  edit: commonLabels.edit,
  editDevice: 'Edit node',
  expand: commonLabels.expand,
  firmwareUpdates: 'Firmware updates',
  groups: 'Groups',
  id: 'ID',
  generalInfo: 'General info',
  hideNodeOptions: 'Hide node options',
  info: commonLabels.info,
  manageDevice: 'Manage node',
  managerVersion: 'MANAGER VERSION',
  modalErrorVersionText1: 'The OS version installed in this node are no longer supported.',
  modalErrorVersionText2: 'Please, ',
  modalErrorVersionText3: 'update OS to recover normal functionality',
  modalErrorVersionText4: ' in panel.',
  modalErrorVersionText5: 'All applications installed in this node are ',
  modalErrorVersionText6: 'not affected',
  modalErrorVersionText7: ' by this limitation and ',
  modalErrorVersionText8: 'are still operating normally in the background',
  modalErrorVersionTitle: 'THIS NODE MUST BE UPDATED',
  name: commonLabels.name,
  network: 'Networking',
  new: 'New',
  osVersion: 'OS VERSION',
  secrets: 'Global secrets',
  showNodeOptions: 'Show node options',
  tags: 'Tags',
  telemetry: 'Telemetry',
  updateAvailable: 'Update available',
  updateNodeManager: 'Update node manager',
  updateOS: 'Update OS version',
  dockerVolumes: 'Docker volumes',
  appNative: 'Native app',
  appDocker: 'Docker app',
  appMarket: 'Docker app',
  model: 'Model'
};
export const appDeviceLabels: AppDeviceLabels = {
  collapse: commonLabels.collapse,
  hideCard: 'Hide card',
  expand: commonLabels.expand,
  pending: 'Pending',
  pendingCommands: 'Incomplete commands',
  appOutdated: 'New version available',
  appBuilding: 'Building',
  appPreparing: 'Preparing',
  appRunning: 'Running',
  appStopped: 'Stopped',
  appError: 'Error',
  appConfiguring: 'Configuring',
  showOptions: 'Show options',
  hideOptions: 'Hide options',
  processes: 'Processes',
  dockerComposeInfo: 'Docker compose info',
  persistantFolder: 'Persistant folder'
};
export const infoDetailLabels: InfoDetailLabels = {
  lastSeen: 'Last seen',
  activate: 'Enable',
  enableProxy: 'Enable proxy',
  add: commonLabels.add,
  architecture: 'Architecture',
  collapse: commonLabels.collapse,
  connected: 'CONNECTED',
  connectionStatus: 'CONNECTION STATUS',
  coordinates: 'Edit location',
  copy: 'Copy to clipboard',
  disableProxy: 'Disable proxy',
  cpu: 'CPU TEMPERATURE',
  delete: 'Delete',
  device: commonLabels.device,
  disconnected: 'DISCONNECTED',
  edit: commonLabels.edit,
  editConfig: commonLabels.editConfig,
  expand: commonLabels.expand,
  family: 'Family',
  group: 'GROUP',
  hardware: 'Hardware',
  id: 'BARBARA ID',
  lastUpdated: 'Last update',
  loadingInfo: 'Loading node info...',
  location: 'Location',
  map: commonLabels.map,
  network: 'Network status',
  notAvailable: 'Not available',
  noData: 'No data',
  node: 'Node',
  nodeMetrics: 'Node metrics',
  metrics: 'metrics',
  time: 'time',
  noProvisionedText: 'ACTIVATE THE NODE TO RECEIVE DATA',
  noTags: 'No tags assigned',
  organization: 'ORGANIZATION',
  upSince: 'Up since',
  vpn: commonLabels.vpn,
  vpnErrorConfig: 'THE CONFIGURATION AND STATUS OF THE VPN IS NOT CORRECT'
};
export const dockerDeviceLabels: DockerDeviceLabels = {
  cleaningData: commonLabels.cleaningData,
  collapse: commonLabels.collapse,
  config: 'Custom Config',
  delete: commonLabels.delete,
  enviromentVariables: 'Environment variables',
  deleting: commonLabels.deleting,
  deletingApps: commonLabels.deletingApps,
  appConfig: 'Configuration',
  dockerInfo: 'Info',
  dockerComposeInfo: 'Docker compose info',
  noDockerComposeInfo: 'No docker compose info available',
  dockerLogs: 'LOG',
  dockerName: 'DOCKER NAME',
  dockerPersist: 'Persistant folder',
  expand: commonLabels.expand,
  noInstalledDocker: 'NO APP INSTALLED',
  refresh: commonLabels.refresh,
  runningApp: commonLabels.runningApp,
  sendDocker: 'SEND DOCKER',
  startApp: commonLabels.startApp,
  startAppCapitalize: commonLabels.startAppCapitalize,
  stateInfo: 'INFO: Building docker space',
  stopApp: commonLabels.stopApp,
  stopAppCapitalize: commonLabels.stopAppCapitalize,
  stopBuild: 'STOP BUILD',
  stoppedApp: commonLabels.stoppedApp,
  title: commonLabels.status,
  update: commonLabels.update,
  updating: commonLabels.updating,
  updatingApps: 'UPDATING APPLICATION',
  upload: commonLabels.newVersion,
  waiting: 'WAITING...'
};

export const analyticsLabels: AnalyticsLabels = {
  absoluteTabLabel: 'Absolute',
  export: commonLabels.export,
  exportChartAsLabel: 'Export chart as',
  lastLabel: 'Show last',
  noData: 'No data available',
  modalTitle: 'Analytics settings',
  rangeLabel: 'Select a date range',
  relativeTabLabel: 'Relative',
  selectMetricLabel: 'Select metric',
  selectSourcesLabel: 'Select sources',
  selectTimeLabel: 'Select time unit',
  configureChart: 'Configure chart'
};
export const appConfig: AppConfigLabels = {
  addNewGroup: 'Add new group',
  duplicateName: 'Duplicate group name',
  key: 'Key',
  lastUpdate: 'LAST CONFIG SENT',
  loading: 'Loading...',
  name: commonLabels.name,
  noDataFound: commonLabels.noDataFound,
  refresh: commonLabels.refresh,
  requiredName: 'Group name is required',
  sendConfiguration: 'SEND',
  update: commonLabels.update,
  value: 'Value'
};
export const nativeAppLabel: NativeAppLabel = {
  appLogs: 'APP LOGS',
  appName: 'APP NAME',
  appStatus: 'APP STATUS',
  cleaningData: commonLabels.cleaningData,
  deleting: commonLabels.deleting,
  deletingApps: commonLabels.deletingApps,
  installingApp: 'INSTALLING APPLICATION',
  noAppInstalled: 'NO APPLICATION INSTALLED',
  persistApp: 'APP PERSISTENT FOLDER',
  runningApp: commonLabels.running,
  sendApp: 'SEND APP',
  startApp: commonLabels.startApp,
  startingApp: 'STARTING ...',
  stopApp: commonLabels.stopApp,
  stoppedApp: commonLabels.stoppedApp,
  stoppingApp: 'STOPPING ...',
  updating: commonLabels.updating,
  zeroPersist: '0 MB'
};
export const telemetryLabels: TelemetryLabels = {
  collapse: commonLabels.collapse,
  config: commonLabels.config,
  expand: commonLabels.expand,
  frequency: 'Frequency',
  noData: 'NO DATA',
  process: 'Processes',
  runningProcess: 'Running Process',
  runningProcesses: 'Running Processes',
  title: 'Edit telemetry frequency',
  modalTitle: 'Processes Telemetry',
  name: 'Name'
};
export const logLabels: LogLabels = {
  activate: commonLabels.activateCapitalize,
  delete: commonLabels.delete,
  exportCSV: commonLabels.exportCSV,
  filter: commonLabels.filter,
  search: commonLabels.search,
  follow: commonLabels.follow,
  timeStamp: commonLabels.timeStamp,
  wrap: commonLabels.wrap,
  openInNewWindow: 'Open in a new window',
  darkMode: 'Dark mode',
  lightMode: 'Light mode'
};
export const managerUpdateModalLabels: ManagerUpdateModalLabels = {
  avalaibleVersionLabel: 'AVAILABLE VERSION',
  close: commonLabels.close,
  lastVersionLabel: 'Last version',
  mbCapitalize: commonLabels.mbCapitalize,
  agent: 'Node Manager Update available',
  modalTitle: 'UPDATE NODE MANAGER',
  osVersion1: 'Your OS version does not allow to update the Node Manager. Please update the OS ',
  osVersion2: ' version first .',
  sendFullUpdateLabel: 'SEND FULL UPDATE',
  sendFullUpdateValue1: 'Full updates contain all the data of the new version. ',
  sendFullUpdateValue2:
    'In a full update all the contents of the Node Manager are entirely substituted by this new version.',
  sendFullUpdateValue3:
    'No application or setting will be lost in this process. The node will reboot after update is installed.',
  sendUpdateLabel: 'Send update',
  sizeLabel: 'Size',
  updatingLabel: commonLabels.updatingCapitalize,
  versionLabel: 'Node Manager Version ',
  versionOutOfDateLabel: 'This version is out of date',
  versionTitle: 'CURRENT VERSION'
};
export const updateModalLabels: UpdateModalLabels = {
  avalaibleVersionLabel: 'AVAILABLE VERSION',
  close: commonLabels.close,
  incrementalVersionLabel: 'Incremental version',
  lastVersionLabel: 'Last version',
  mbCapitalize: commonLabels.mbCapitalize,
  modalTitle: 'UPDATE NODE',
  sendFullUpdateLabel: 'SEND FULL UPDATE',
  sendFullUpdateValue1: 'Full updates contain all the data of the new version. ',
  sendFullUpdateValue2:
    'In a full update all the contents of the Operating System are entirely substituted by this new version.',
  sendFullUpdateValue3:
    'No application or setting will be lost in this process. The node will reboot after update is installed.',
  sendIncrementalUpdateLabel: 'SEND INCREMENTAL UPDATE (Recommended)',
  sendIncrementalUpdateValue1:
    'Incremental updates only contain the data modified or added in the new version. In an incremental ',
  sendIncrementalUpdateValue2:
    'update the Operating System is patched to cover the gap between the current version and the new one.',
  sendIncrementalUpdateValue3:
    'No application or setting will be lost in this process. The node will reboot after update is installed.',
  sendUpdateLabel: 'Send update',
  sizeLabel: 'Size',
  updatingLabel: commonLabels.updatingCapitalize,
  versionLabel: 'OS Version ',
  os: 'OS Update available',
  versionOutOfDateLabel: 'This version is out of date',
  versionTitle: 'CURRENT VERSION'
};
export const dockerSettingsModalLabels: DockerSettingsModalLabels = {
  actions: commonLabels.actions,
  add: commonLabels.add,
  addAsset: commonLabels.addAsset,
  rollback: 'Enable Rollback',
  ssidHidden: 'This SSID is hidden',
  assets: commonLabels.assets,
  copy: commonLabels.copy,
  show: commonLabels.show,
  hide: commonLabels.hide,
  back: commonLabels.back,
  regenerate: 'Regenerate password',
  cancel: commonLabels.cancel,
  close: commonLabels.close,
  showPassword: 'Show password',
  standaloneCredentials: 'Standalone Settings',
  confirmDeleteAllAssetsButton: 'Yes, I want to delete all assets',
  confirmDeleteAllAssetsHeader: 'Delete All Assets',
  confirmDeleteAllAssetsText: 'Once deleted, you will not be able to recover it.',
  confirmDeleteAllAssetsTitle: 'Are you sure you want to delete all assets?',
  confirmDeleteAssetButton: 'Yes, I want to delete the asset',
  confirmDeleteAssetHeader: 'Delete Asset',
  confirmDeleteAssetText: 'Once deleted, you will not be able to recover it.',
  confirmDeleteAssetTitle: 'Are you sure you want to delete the asset?',
  confirmPruneAllButton: 'Prune Docker',
  confirmPruneAllHeader: 'Docker Prune',
  confirmPruneAllText:
    'Once pruned, all unused images, volumes networks and containers will be removed from the Edge Node.',
  confirmPruneAllTitle: 'Are you sure you want to prune Docker?',
  confirmPruneContainersButton: 'Yes, I want to prune containers',
  confirmPruneContainersHeader: 'Prune Containers',
  confirmPruneContainersText: 'Once pruned, all unused containers will be removed from the node.',
  confirmPruneContainersTitle: 'Are you sure you want to prune Docker Containers?',
  confirmPruneImagesButton: 'Yes, I want to prune images',
  confirmPruneImagesHeader: 'Prune Images',
  confirmPruneImagesText: 'Once pruned, all unused list will be removed from the node.',
  confirmPruneImagesTitle: 'Are you sure you want to prune Docker Images?',
  confirmPruneNetworksButton: 'Yes, I want to prune networks',
  confirmPruneNetworksHeader: 'Prune Networks',
  confirmPruneNetworksText: 'Once pruned, all unused networks will be removed from the node.',
  confirmPruneNetworksTitle: 'Are you sure you want to prune Docker Networks?',
  confirmPruneVolumesButton: 'Yes, I want to prune volumes',
  confirmPruneVolumesHeader: 'Prune Volumes',
  confirmPruneVolumesText: 'Once pruned, all unused volumes will be removed from the node.',
  confirmPruneVolumesTitle: 'Are you sure you want to prune Docker Volumes?',
  confirmRestartButton: 'Yes, I want to restart',
  confirmRestartHeader: 'Restart Docker',
  confirmRestartText:
    'All the running docker apps will be re-spawned once the daemon is ready again.',
  confirmRestartTitle: 'Are you sure you want to restart the Docker Daemon of the Edge Node?',
  delete: commonLabels.delete,
  deleteAll: commonLabels.deleteAll,
  download: commonLabels.download,
  export: commonLabels.export,
  file: commonLabels.file,
  found: 'found',
  infoPruneAllText1:
    'Prune allows to remove unused elements (list, containers, networks and volumes) in Docker system.',
  infoRestartText1: 'Allows to restart the Docker Daemon in the Edge Node. ',
  infoRestartText2:
    'All the running docker apps will be re-spawned once the daemon is ready again.',
  less: commonLabels.less,
  login: 'User name',
  messageErrorSize: 'SORRY. Assets need to be smaller than 10 MB.',
  modalTitle: 'Advanced Actions',
  more: commonLabels.more,
  name: 'Name',
  noAssets: 'No assets available',
  nodeAssets: 'Node Assets',
  pruneAll: 'Prune all',
  pruneContainers: 'Prune Containers',
  pruneDocker: 'Docker Prune',
  pruneImages: 'Prune Images',
  pruneNetworks: 'Prune Networks',
  pruneVolumes: 'Prune Volumes',
  restart: commonLabels.restart,
  restartDocker: 'Restart Docker Daemon',
  send: 'Send',
  status: commonLabels.status,
  total: 'Total',
  upload: commonLabels.upload,
  uploading: commonLabels.uploading,
  value: 'value'
};
export const manageModalLabels: ManageModalLabels = {
  activate: 'Activate',
  back: commonLabels.back,
  close: commonLabels.close,
  confirmActivateButton: 'Yes, I want to Activate',
  confirmActivateHeader: 'Are you sure you want to activate the Edge Node?',
  confirmActivateText:
    'Once activated, it can start sending telemetry data and receiving commands.',
  confirmActivateTitle: 'Are you sure you want to activate the Edge Node?',
  confirmDeactivateButton: 'Yes, I want to Deactivate',
  confirmDeactivateHeader: 'Are you sure you want to deactivate the Edge Node?',
  confirmDeactivateText: 'Once deactivated, it can be re-activated again.',
  confirmDeactivateText2: '',
  cautionTextDeactivate:
    'CAUTION: If the Node’s factory default version did not have a valid network configuration it will not be reachable.',
  confirmDeactivateTitle: 'Are you sure you want to deactivate the Edge Node?',
  confirmDeleteButton: 'Yes, I want to Delete',
  confirmDeleteHeader: 'Are you sure you want to delete the Edge Node?',
  confirmDeleteText:
    'Once deleted, it can be added back. All Node contents (applications, models, configurations, etc.) will be lost.',
  confirmDeleteText2: '',
  cautionTextDelete:
    'CAUTION: If the Node’s factory default version did not have a valid network configuration it will not be reachable.',
  confirmDeleteTitle: 'Are you sure you want to delete the Edge Node?',
  confirmRebootButton: 'Yes, I want to Reboot',
  confirmRebootHeader: 'Are you sure you want to reboot the Edge Node?',
  cancel: commonLabels.cancel,
  confirmRebootText:
    'The Node will be disconnected while rebooting. All the running apps will be re-spawned once the node is back up.',
  confirmRebootTitle: 'Are you sure you want to reboot the Edge Node?',
  confirmShutDownButton: 'Yes, I want to Shut Down',
  cautionTextShutDown:
    'CAUTION: Once shut down, it requires a physical manual action to restart it.',
  confirmShutDownHeader: 'Shut Down',
  confirmShutDownText: '',
  confirmShutDownTitle: 'Are you sure you want to shut down the Edge Node?',
  confirmWipeAndBrickButton: 'Yes, I want to Wipe and Brick',
  confirmWipeAndBrickHeader: 'Wipe and Brick',
  confirmWipeAndBrickText: '',
  cautionTextWipeAndBrick:
    'CAUTION: Once wiped, it requires a physical manual action to restart it.',
  confirmWipeAndBrickTitle: 'Are you sure you want to wipe and brick the Edge Node?',
  deactivate: 'Deactivate',
  delete: commonLabels.delete,
  infoActivateText: 'Enables the Edge Node to start sending telemetry data and receiving commands.',
  infoDeactivateText1:
    'Allows to perform a factory reset of the Edge Node, including its network configuration. It will still be shown in the list ',
  infoDeactivateText2:
    'of nodes and can be easily re-activated. However if its factory default did not have a valid network configuration, ',
  infoDeactivateText3: 'the node will not be reachable.',
  infoDeleteText1:
    'Allows to deactivate and remove the Node from the list. It will no longer be shown in the list but can be added back. ',
  infoDeleteText2:
    'However if its factory default did not have a valid network configuration, the node will not be reachable.',
  infoRebootText1: 'Allows to reboot the Edge Node. It will be disconected while rebooting. ',
  infoRebootText2: 'All the running apps will be re-spawned once the node is back up.',
  infoShutDownText:
    'Allows to shut down the Edge Node remotely. Once shut down, the node would require a physical manual action to restart it.',
  infoWipeAndBrickText1:
    'Allows you to delete all the information in the Node. Once wiped, the node would require a physical manual ',
  infoWipeAndBrickText2: 'action to completely re-install it.',
  messageWarning: `Be careful with these options. They can't be reverted.`,
  modalTitle: 'Node Management',
  reboot: commonLabels.reboot,
  shutDown: commonLabels.shutDown,
  wipeAndBrick: commonLabels.wipeAndBrick,
  labelForm: 'Type the action you wish to complete',
  placeholderCommon: 'e.g. '
};
export const editDeviceModalLabels: EditDeviceModalLabels = {
  add: commonLabels.add,
  addAsset: commonLabels.addAsset,
  addTag: commonLabels.addTag,
  assets: commonLabels.assets,
  back: commonLabels.back,
  changeImage: commonLabels.changeImage,
  close: commonLabels.close,
  delete: commonLabels.delete,
  download: commonLabels.download,
  messageErrorSize: 'SORRY. Assets need to be smaller than 10 MB.',
  modalTitle: 'EDIT NODE',
  name: commonLabels.name,
  noAssets: 'NO ASSETS AVAILABLE',
  tag: commonLabels.tag,
  tagList: 'Tags list',
  upload: commonLabels.upload,
  uploading: commonLabels.uploading
};
export const uploadModalLabels: UploadModalLabels = {
  application: 'Application',
  model: 'Model',
  back: commonLabels.back,
  titleModel: 'Model',
  buttonUpload: 'Upload file',
  cancel: commonLabels.cancel,
  configuration: 'Configure application',
  existingVersion: 'Existing version',
  forceDocker: 'Force Docker image download',
  newVersion: 'New version',
  next: commonLabels.next,
  nextSecrets: 'Add variables',
  runAutomaticallyAfterInstall: 'Run automatically after install',
  enabledLogs: 'Activate logs for this application',
  selectApp: 'Select Application',
  selectVersion: 'Select version',
  send: 'Send',
  textInfoAplication: 'Choose the application you want to deploy',
  textInfoModel: 'Choose the model you want to run',
  textInfoApplication: 'Choose the App you want to run in this space',
  textInfoVersion: 'Choose the version you want to run',
  titleDocker: 'Docker application',
  titleMarketplace: 'Marketplace application',
  titleNative: 'Native application',
  selectModel: 'Select Model',
  uploadFile: 'Upload file',
  version: 'version',
  versionName: 'Version name'
};
export const exportCsvModalLabels: ExportCsvModalLabels = {
  byLines: 'By lines',
  byTime: 'By time range',
  cancel: commonLabels.cancel,
  datePickerLabel: 'Enter a date range',
  exportLabel: 'Export',
  exportText: 'Choose an export mode.',
  isRequired: ' is required',
  last: 'Last',
  lines: 'Lines',
  ok: commonLabels.ok,
  title: 'Export logs to csv'
};
export const pictureModalLabels: PictureModalLabels = {
  cancel: commonLabels.cancel,
  titleIcon: 'Crop icon',
  titleImagen: 'Crop picture',
  upload: commonLabels.upload,
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out'
};
export const changeImageModalLabels: ChangeImageModalLabels = {
  background: 'Background',
  cancel: commonLabels.cancel,
  color: 'Color',
  delete: commonLabels.delete,
  fontSize: 'Font-size',
  generate: commonLabels.generate,
  generateIcon: 'Generate custom icon',
  generatePicture: 'Generate custom picture',
  textPlaceholder: 'Enter text',
  titleDevicePicture: 'Node picture',
  titleIcon: 'Application icon',
  titlePicture: 'Application picture',
  upload: commonLabels.upload
};
export const variablesLabels: VariablesLabels = {
  environmentVariable: 'Environment variable',
  environmentVariables: 'Environment variables',
  name: commonLabels.name,
  environmentVariablesEmpty: 'No environment variables available',
  value: 'Value',
  showPassword: 'Show password',
  hidePassword: 'Hide password',
  close: commonLabels.close
};
export const credentialsLabels: CredentialsLabels = {
  add: commonLabels.add,
  addCredentialFromFile: 'Add credential from file',
  addCredentials: 'Add credential',
  required: ' is required',
  addNewCredential: 'Add new credential',
  cancel: commonLabels.cancel,
  invalidUrl: 'Invalid URL',
  close: commonLabels.close,
  comfirmDeleteAllCredentialText1: 'All credentials ',
  comfirmDeleteAllCredentialText2: ' will be deleted from the node. This action cannot be undone.',
  confirmDeleteAllCredentialTitle: 'Are you sure you want to delete all credentials?',
  confirmDeleteCredentialText: 'The credential ',
  confirmDeleteCredentialText2: ' will be deleted from the node. This action cannot be undone.',
  confirmDeleteCredentialTitle: 'Are you sure you want to delete this credential?',
  credential: 'Credential',
  credentials: 'Credentials',
  delete: commonLabels.delete,
  deleteAll: 'Delete all credentials',
  deleteCredential: 'Delete credential',
  dockerCredential: commonLabels.dockerCredential,
  downloadTemplate: 'Download template',
  downloadFile: 'Download file',
  edit: commonLabels.edit,
  editCredential: 'Edit credential',
  errorAddingCredentials: 'Error adding credentials',
  errorCredentialAlreadyExist:
    'The following credentials already exist. Their actual value will be overwritten.',
  errorCredentialEmptyValue: 'The following credentials have empty values. They will be ignored.',
  errorCsvFormat: 'Incorrect credential csv format.',
  errorCsvTemplate: 'Check your cvs file has the same structure that the template provided.',
  errorCsvEmpty: 'No credentials found.',
  export: commonLabels.export + ' credentials',
  fileImportSummary: 'File import summary',
  hidePassword: 'Hide password',
  import: 'Import credentials',
  lastUpdate: 'Last update',
  loadingInfo: 'Loading credential info...',
  more: 'More',
  noCredentials: 'No credentials available',
  noDataFound: 'No data found',
  ok: 'OK',
  password: 'Password',
  repository: 'Repository',
  save: commonLabels.save,
  selectFile: 'Select file to upload',
  showPassword: 'Show password',
  status: 'Status',
  totalCredentialsFound: 'Credentials found',
  upload: 'Upload',
  uploadFile: 'Upload file',
  uploadFromFile: 'Upload from file',
  userName: 'User name'
};

export const volumesLabels: VolumesLabels = {
  add: 'Add new volume',
  app: 'App',
  cancel: commonLabels.cancel,
  close: commonLabels.close,
  container: 'Container',
  containers: 'Containers',
  created: 'Created',
  delete: commonLabels.delete,
  inUse: 'in use',
  lastUpdate: 'Last' + ' updated: ',
  loadingInfo: 'Loading volume info...',
  name: 'Name',
  noDataFound: 'No data found',
  noVolumes: 'No available volumes.',
  ok: 'OK',
  prune: 'Prune',
  service: 'Service',
  target: 'Target',
  usedBy: 'Used by',
  volume: 'Volume',
  volumeName: 'Volume name',
  volumes: 'Volumes'
};
export const secretsLabels: SecretsLabels = {
  add: 'Add',
  addFromTxt: 'Add secret from txt',
  addNewSecret: 'Add new secret',
  addSecretFromFile: 'Add secret from file',
  addSecrets: 'Add secret',
  cancel: commonLabels.cancel,
  close: commonLabels.close,
  comfirmDeleteAllSecretText1: 'All secrets ',
  comfirmDeleteAllSecretText2: ' will be deleted from the node. This action cannot be undone.',
  confirmDeleteAllSecretTitle: 'Are you sure you want to delete all secrets?',
  confirmDeleteSecretText: 'The secret ',
  confirmDeleteSecretText2: ' will be deleted from the node. This action cannot be undone.',
  confirmDeleteSecretTitle: 'Are you sure you want to delete this secret?',
  delete: 'Delete',
  deleteAll: 'Delete all secrets',
  deleteSecret: 'Delete secret',
  downloadTemplate: 'Download template',
  downloadFile: 'Download file',
  edit: 'Edit',
  editSecret: 'Edit secret',
  errorAddingSecrets: 'Error adding secrets',
  errorSecretAlreadyExist:
    'The following secrets already exist. Their actual value will be overwritten.',
  errorSecretEmptyValue: 'The following secrets have empty values.',
  errorTxtFormat: 'No secrets found or incorrect format.',
  errorTxtTemplate: '',
  export: 'Export secrets',
  fileImportSummary: 'File import summary',
  fromFile: 'Add file as a secret',
  hidePassword: 'Hide password',
  import: 'Import secrets',
  lastUpdate: 'Last update',
  loadingInfo: 'Loading secret info...',
  more: 'More',
  name: 'Name',
  noDataFound: 'No data found',
  noSecrets: 'No secrets available',
  ok: 'OK',
  save: 'Save',
  secret: 'Secret',
  secrets: 'Secrets',
  secretName: 'Secret name',
  secretValue: 'Secret value',
  selectFile: 'Select file to upload',
  showPassword: 'Show password',
  status: 'Status',
  totalSecretsFound: 'Secrets found',
  unauthorized: 'Unauthorized',
  upload: 'Upload',
  uploadFile: 'Upload file',
  uploadFromFile: 'Upload from file',
  value: 'Value'
};

export const confirmDialogLabelsProxy: any = {
  button1Message: 'Reboot now',
  button1Color: 'primary',
  secondSubtitle: '',
  contentMessage:
    '<p style="color:#000; font-size: 16px; margin-bottom: 16px; font-weight: 400; line-height: 140%">New proxy settings will not be applied until the node is rebooted. This process should take a few minutes.</p> <p  style="color:#000; font-size: 16px;  font-weight: 400; line-height: 140%">Please, reboot your device to apply settings.</p>',
  titleMessage: 'Pending Reboot'
};
export const confirmDialogLabelsProxyRollback: any = {
  button1Message: 'Send with rollback',
  button2Message: 'Skip',
  button1Color: 'primary',
  button2Color: 'primary',
  secondSubtitle: '',
  contentMessage:
    '<p style="color:#000; font-size: 16px; margin-bottom: 16px; font-weight: 400; line-height: 140%">To proceed choose between sending the command with rollback* or without.</p> <p  style="color:#777; font-size: 14px; font-weight: 500; line-height: 140%">*The rollback feature will revert your device’s network to the previous one if the new network setting fails.</p>',
  titleMessage: 'Proxy Off'
};
